import * as React from "react"

import * as styles from "./Home.module.css"
import Header from "../header"
import Footer from "../footer"
import MapContainer from "../mapContainer"
import { StaticImage } from "gatsby-plugin-image"

import Loader from "../Loader"

import { useIntl } from "gatsby-plugin-intl-v4"

import ReactFullpage from "@fullpage/react-fullpage";

import ArtDeVivreSVG from '../../assets/svg/txt_art-de-vivre.svg'
import ArtOfLivingSVG from '../../assets/svg/txt_art-of-living.svg'
import Occupancy2024SVG from '../../assets/svg/txt_occupancy2024.svg'
import Occupation2024SVG from '../../assets/svg/txt_occupation2024.svg'

import PictureAndList from "../pictureAndList"
import TextWithImagesInline from "../textWithImagesInline"
import CentralVideo from "../centralVideo"
import SectionVideoAndPictureCentered from "../sectionVideoAndPictureCentered"
import CentralVideoWith4Texts from "../centralVideoWith4Texts"
import SectionPictureCenteredAndVideo from "../sectionPictureCenteredAndVideo"
import CentralVideoWithBackground from "../centralVideoWithBackground"
import ButtonV2 from "../buttonV2"
import HomePageModal from "../homepageModal";

const Home = () => {
    const intl = useIntl();
    const [showConstructionModal, setShowConstructionModal] = React.useState(false);

    return (
        <div id="homePage" className={`pageContainer ${styles.bg}`}>
            <Header hideWhenLoading={false} hidePlaceholder={true} />

            <HomePageModal/>

            <div className={styles.fixedConstructionButton}>
                <ButtonV2 content={
                    <span>
                        <span className={styles.buttonBall}></span>
                        <span>{intl.formatMessage({ id: 'homepage.constructionButton' })}</span>
                    </span>
                }
                    style={{ color: "black" }}
                    onClick={() => setShowConstructionModal(true)}
                />
            </div>
            <div className={`${styles.modalConstruction} ${showConstructionModal === true ? "" : styles.hide}`}>
                <div className={styles.iframeContainer}>
                    <iframe
                        title="Live construction | Construction en direct"
                        src="https://www.devisubox.com/dv/dv.php5?pgl=Project/interface&sRef=BUF3LSLLR"
                        allowfullscreen
                        style={{ border: "0", width: "748px", height: "500px", overflow: "hidden" }}
                    >
                    </iframe>
                    <div className={styles.closeModal} role='button' tabIndex={0} onKeyDown={() => setShowConstructionModal(false)} onClick={() => setShowConstructionModal(false)}>
                        <StaticImage
                            className={styles.cross}
                            src='../../images/common/white-cross.png'
                            loading="eager"
                            layout="constrained"
                            quality={100}
                            height={25}
                            formats={["auto", "webp", "avif"]}
                            alt={intl.formatMessage({ id: 'alts.closeCross' })}
                            placeholder="none"
                        />
                    </div>
                </div>
            </div>
            <Loader id="loader" />
            <ReactFullpage
                licenseKey={'WNK68-CN51I-6KNC7-J1276-CSYRO'}
                sectionSelector={"section"}
                normalScrollElements={'#loader'}
                easingcss3='cubic-bezier(.29,0,.25,1.01)'
                afterRender={function () {
                    //only once

                    document.querySelectorAll("section")[1].classList.add("coming");

                }}
                afterLoad={function (origin, destination, direction) {

                    destination.item.classList.remove("coming");
                    destination.item.classList.remove("leaving");
                    if (direction === "down" && !destination.isLast) {
                        document.querySelectorAll("section")[destination.index + 1].classList.add("coming");
                    }
                    if (direction === "up" && !destination.isFirst) {
                        document.querySelectorAll("section")[destination.index - 1].classList.add("coming");
                    }

                    setTimeout(() => {
                        if (destination.item.classList.contains("footerSection")) {
                            destination.item.classList.remove("fp-auto-height");
                        }
                    }, 250)

                }}
                onLeave={function (origin, destination, direction) {

                    if (direction === "down") {

                        document.querySelectorAll("section").forEach((e) => {
                            e.classList.remove("coming");
                            e.classList.remove("leaving");
                        });
                        if (!destination.isLast) {
                            document.querySelectorAll("section")[destination.index + 1].classList.add("coming");
                        }
                        origin.item.classList.add("leaving");
                    }
                    if (direction === "up") {

                        document.querySelectorAll("section").forEach((e) => {
                            e.classList.remove("coming");
                            e.classList.remove("leaving");
                        });
                        if (!destination.isFirst) {
                            document.querySelectorAll("section")[destination.index - 1].classList.add("coming");
                        }
                        origin.item.classList.add("leaving");
                    }

                    if (origin.item.classList.contains("footerSection")) {
                        origin.item.classList.add("fp-auto-height");
                    }

                }}
                render={(comp) => (
                    <ReactFullpage.Wrapper>
                        <CentralVideoWithBackground />
                        <SectionPictureCenteredAndVideo />
                        <CentralVideoWith4Texts />
                        <SectionVideoAndPictureCentered />
                        <CentralVideo />
                        <section className={styles.sectionFullPicture}>
                            <ArtOfLivingSVG width="100%" className={`${styles.titleAbsolute} ${intl.locale === 'en' ? "" : styles.hide}`} />
                            <ArtDeVivreSVG width="100%" className={`${styles.titleAbsolute} ${intl.locale === 'fr' ? "" : styles.hide}`} />
                            <StaticImage
                                src='../../images/homepage/Mansfield_016_L.jpg'
                                loading="eager"
                                placeholder="dominantColor"
                                layout="fullWidth"
                                quality={90}
                                formats={["auto", "webp", "avif"]}
                                alt={intl.formatMessage({ id: 'alts.livingRoom' })}
                                style={{
                                    height: "100%",
                                    maxWidth: "100%"
                                }}
                            />
                        </section>
                        <TextWithImagesInline />
                        <PictureAndList />
                        <section><MapContainer /></section>
                        <section className="footerSection fp-auto-height">
                            <div className={styles.sectionLastImage}>
                                <Occupancy2024SVG width="100%" className={`${styles.titleHalfScreen} ${intl.locale === 'en' ? "" : styles.hide}`} />
                                <Occupation2024SVG width="100%" className={`${styles.titleHalfScreen} ${intl.locale === 'fr' ? "" : styles.hide}`} />
                                <StaticImage
                                    src='../../images/homepage/Mansfield_016_L.jpg'
                                    className={styles.footerImg}
                                    loading="eager"
                                    placeholder="blurred"
                                    layout="fullWidth"
                                    quality={90}
                                    formats={["auto", "webp", "avif"]}
                                    alt={intl.formatMessage({ id: 'alts.livingRoom' })}
                                    style={{
                                        maxHeight: "50vh"
                                    }}
                                />
                            </div>
                            <Footer hideBorder={true} />
                        </section>
                    </ReactFullpage.Wrapper>
                )} />

        </div>
    )
}

export default Home
