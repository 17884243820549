import React from 'react'
import {Modal} from 'react-bootstrap';
import {useIntl} from "gatsby-plugin-intl-v4"
import {StaticImage} from "gatsby-plugin-image";
import '../styles/homepageModal.css';

export default function HomePageModal() {
    const [showModal, setShowModal] = React.useState(true);
    const intl = useIntl();
    const currentLanguage = intl.locale

    return (
        <Modal
            size="xl"
            centered
            contentClassName={'rounded-0 text-dark'}
            show={showModal} 
            onHide={() => setShowModal(false)}
        >
            <Modal.Body className="p-0">
                <div className="homepage-modal">
                    <button 
                        className="modal-close-button" 
                        onClick={() => setShowModal(false)}
                    >
                        X
                    </button>
                    <div className="image-container">
                        <StaticImage
                            className="modal-background"
                            src="../images/popup/background.png"
                            alt="Background image"
                        />
                        <div className="image-overlay-text">
                            {intl.formatMessage({id: 'homepage.popup.title'})}
                        </div>
                    </div>

                    <div className="modal-intro">
                        {intl.formatMessage({id: 'homepage.popup.subtitle'})}
                    </div>

                    <div className="modal-description">
                        {intl.formatMessage({id: 'homepage.popup.description.1'})} • {intl.formatMessage({id: 'homepage.popup.description.2'})}
                    </div>

                    <div className="modal-description">
                        {intl.formatMessage({id: 'homepage.popup.description.3'})} • {intl.formatMessage({id: 'homepage.popup.description.4'})}
                    </div>

                    <div className="modal-description">
                        {intl.formatMessage({id: 'homepage.popup.description.5'})}
                        <sup> {intl.formatMessage({id: 'homepage.popup.description.pow'})}</sup>
                    </div>

                    <div className="modal-button">
                        <button onClick={() => window.open(intl.formatMessage({id: 'homepage.popup.button.link'}), '_blank')}>
                            {intl.formatMessage({id: 'homepage.popup.button'})}
                        </button>
                    </div>

                    <div className="modal-disclaimer">
                        {intl.formatMessage({id: 'homepage.popup.disclaimer'})}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
